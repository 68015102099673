import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import classes from "./Overview.module.css";
import { company } from "../../config/config";

const Overview = () => {
  return (
    <section className={classes.overview}>
      <Helmet>
        <title>{company} Overview</title>
        <meta
          name="description"
          content="Read genuine reviews from valued clients at Orohandel. Discover how we've assisted individuals and businesses in achieving their financial goals."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <h2>{company} Overview</h2>
      <p>
        <strong>{company}</strong> is an investment firm with a unique history.
        While it currently operates under the {company} name, this entity is the
        result of a merger from a previously established company in the
        financial sector. Though the {company} brand might appear as a newcomer
        in the financial arena, it draws from a rich legacy inherited from its
        predecessor, seamlessly combining years of experience with fresh
        strategies.
      </p>
      <p>
        Central to {company}'s offerings is what's referred to as the{" "}
        <strong>Triad Trade Strategy</strong>. This investment approach
        integrates three key domains: gold, cryptocurrency, and forex trading.
        By amalgamating the strengths of each sector, the strategy aims to
        maximize returns while potentially hedging against individual market
        vulnerabilities. {company}'s approach has been shaped by extensive
        market research, ongoing analysis, and a commitment to adaptability.
      </p>
      <p>
        An intriguing aspect of {company}'s online presence is its{" "}
        <strong>Reviews Page</strong>. This section is dedicated to feedback and
        testimonials exclusively from its investor community. As a measure of
        authenticity, only those who have invested with {company} can pen down
        their experiences and reviews on the platform. A unique feature observed
        on this page is the optional link to the social media profiles of some
        reviewers. This is not a mandatory inclusion but is made available if
        the reviewer opts to share it, adding an additional layer of
        authenticity to the feedback.
      </p>
      <p>
        In the broader financial community, {company} Investments is
        increasingly being recognized not just for its Triad Trade Strategy but
        also for its commitment to transparency and its investor-centric
        approach. As with any investment firm, potential investors and
        stakeholders are encouraged to conduct thorough research, engage with
        available reviews, and possibly interact with the company directly to
        make informed decisions.
      </p>
      <Link to="/reviews">Read reviews</Link>
    </section>
  );
};

export default Overview;
