import { useState } from "react";
import { FcContacts, FcAddressBook, FcFeedback, FcLink } from "react-icons/fc";
import useInput from "../../hooks/userInput";
import { Helmet } from "react-helmet-async";

import classes from "./Write.module.css";
import AuthAlert from "../UI/AuthAlert";
import Spinner from "../UI/Spinner";
import { writeReview } from "../../api/api";
import { company } from "../../config/config";

const Write = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const {
    value: firstnameInput,
    enteredValueIsValid: firstnameInputIsValid,
    hasError: firstnameInputIsInvalid,
    valueInputChangedHandler: firstnameInputChangedHandler,
    valueInputBlurHandler: firstnameInputBlurHandler,
    reset: firstnameInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: lastnameInput,
    enteredValueIsValid: lastnameInputIsValid,
    hasError: lastnameInputIsInvalid,
    valueInputChangedHandler: lastnameInputChangedHandler,
    valueInputBlurHandler: lastnameInputBlurHandler,
    reset: lastnameInputReset,
  } = useInput((value) => value.trim() !== "");
  const {
    value: emailInput,
    enteredValueIsValid: emailInputIsValid,
    hasError: emailInputIsInvalid,
    valueInputChangedHandler: emailInputChangedHandler,
    valueInputBlurHandler: emailInputBlurHandler,
    reset: emailInputReset,
  } = useInput((value) => value.trim().includes("@"));

  const {
    value: commentInput,
    enteredValueIsValid: commentInputIsValid,
    hasError: commentInputIsInvalid,
    valueInputChangedHandler: commentInputChangedHandler,
    valueInputBlurHandler: commentInputBlurHandler,
    reset: commentInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: linkInput,
    enteredValueIsValid: linkInputIsValid,
    hasError: linkInputIsInvalid,
    valueInputChangedHandler: linkInputChangedHandler,
    valueInputBlurHandler: linkInputBlurHandler,
    reset: linkInputReset,
  } = useInput((value) => value.trim().includes("https"));

  let formIsValid = false;
  if (
    firstnameInputIsValid &&
    lastnameInputIsValid &&
    emailInputIsValid &&
    commentInputIsValid &&
    linkInputIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const data = {
      name: `${firstnameInput} ${lastnameInput}`,
      email: emailInput,
      comment: commentInput,
      link: linkInput,
    };

    const res = await writeReview(data);

    if (res.status === "success") {
      setAlertMessage(res.message);
      setAlertStatus(true);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    } else {
      setAlertMessage(res.message);
      setAlertStatus(false);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }

    firstnameInputReset();
    lastnameInputReset();
    emailInputReset();
    commentInputReset();
    linkInputReset();
    setShowSpinner(false);
  };

  const firstnameInputClasses = firstnameInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const lastnameInputClasses = lastnameInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const emailInputClasses = emailInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const commentInputClasses = commentInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const linkInputClasses = linkInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;
  return (
    <form className={classes.form} onSubmit={submitHandler}>
      <Helmet>
        <title>Share your review about {company}</title>
        <meta
          name="description"
          content="Share your experience with Orohandel. Write a review and let us know how Orohandel helped you on your financial journey. Your feedback matters to us!"
        />
        <link rel="canonical" href="/write" />
      </Helmet>
      {showSpinner && <Spinner />}
      {showAlert && <AuthAlert message={alertMessage} status={alertStatus} />}
      <h2>Complete the form to post a review</h2>
      <div className={firstnameInputClasses}>
        <label>First name</label>
        <div className={classes["input-group"]}>
          <FcContacts className={classes.icon} />
          <input
            type="text"
            value={firstnameInput}
            onChange={firstnameInputChangedHandler}
            onBlur={firstnameInputBlurHandler}
          />
        </div>
      </div>
      <div className={lastnameInputClasses}>
        <label>Last name</label>
        <div className={classes["input-group"]}>
          <FcContacts className={classes.icon} />
          <input
            type="text"
            value={lastnameInput}
            onChange={lastnameInputChangedHandler}
            onBlur={lastnameInputBlurHandler}
          />
        </div>
      </div>
      <div className={emailInputClasses}>
        <label>Email</label>
        <div className={classes["input-group"]}>
          <FcAddressBook className={classes.icon} />
          <input
            type="text"
            value={emailInput}
            onChange={emailInputChangedHandler}
            onBlur={emailInputBlurHandler}
          />
        </div>
      </div>
      <div className={commentInputClasses}>
        <label>Comment</label>
        <div className={classes["input-group"]}>
          <FcFeedback className={classes.icon} />
          <textarea
            type="text"
            value={commentInput}
            onChange={commentInputChangedHandler}
            onBlur={commentInputBlurHandler}
          />
        </div>
      </div>
      <div className={linkInputClasses}>
        <label>Social link</label>
        <div className={classes["input-group"]}>
          <FcLink className={classes.icon} />
          <input
            type="text"
            value={linkInput}
            onChange={linkInputChangedHandler}
            onBlur={linkInputBlurHandler}
          />
        </div>
      </div>
      <div className={classes.action}>
        <button type="submit" disabled={!formIsValid}>
          Submit
        </button>
      </div>
    </form>
  );
};

export default Write;
