import { useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReviewItem from "./ReviewItem";
import classes from "./Reviews.module.css";
import { getReviews } from "../../api/api";
import { company } from "../../config/config";

const Reviews = () => {
  const res = useLoaderData();
  const reviews = res.data.reviews;
  return (
    <section className={classes.reviews}>
      <Helmet>
        <title>{company} Overview</title>
        <meta
          name="description"
          content="Read genuine reviews from valued clients at Goldeilen. Discover how we've assisted individuals and businesses in achieving their financial goals."
        />
        <link rel="canonical" href="/reviews" />
      </Helmet>
      {reviews.map((el) => (
        <ReviewItem
          name={el.name}
          comment={el.comment}
          link={el.link}
          createdAt={el.createdAt}
        />
      ))}
    </section>
  );
};

export default Reviews;

export const loader = () => {
  return getReviews();
};
