import classes from "./Footer.module.css";
import { company } from "../../config/config";

const Footer = () => {
  const year = new Date(Date.now()).getFullYear();
  return (
    <footer className={classes.footer}>
      <p>
        <strong>©</strong> {year} {company} Reviews. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
