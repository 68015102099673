import axios from "axios";

// const axiosInstance = axios.create({
//   baseURL: "http://127.0.0.1:5000/api/v1/",
// });

const axiosInstance = axios.create({
  baseURL: "https://api.orohandel.com/api/v1/",
});

export const writeReview = async (data) => {
  try {
    const res = await axiosInstance({
      method: "POST",
      url: "reviews",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getReviews = async () => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: "reviews",
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
