import { FcLink } from "react-icons/fc";

import classes from "./ReviewItem.module.css";
import avatar from "../../images/avatar.jpg";

const ReviewItem = (props) => {
  const time = new Date(props.createdAt).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return (
    <div className={classes.review}>
      <div className={classes.header}>
        <div className={classes.photo}>
          <img src={avatar} alt="avatar" />
        </div>
        <h4>{props.name}</h4>
      </div>
      <p>{props.comment}</p>
      <div className={classes.footer}>
        <p>{time}</p>
        <a href={props.link} target="_blank" rel="noreferrer">
          <FcLink className={classes.icon} />
        </a>
      </div>
    </div>
  );
};

export default ReviewItem;
