import { Fragment } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Layout from "./components/layout/Layout";
import Write from "./components/reviews/Write";
import Reviews from "./components/reviews/Reviews";
import Overview from "./components/overview/Overview";

import { loader as reviewsLoader } from "./components/reviews/Reviews";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Overview />} />
      <Route path="reviews" element={<Reviews />} loader={reviewsLoader} />
      <Route path="write" element={<Write />} />
    </Route>
  )
);

function App() {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;
